<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0 root-container"
    >
      <!-- invoice summary row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 pt-4 px-3 main-card-row"
        no-gutters
      >
        <b-card
          no-body
          class="position-relative overflow-hidden shadow-sm border-1 border-main-green insight-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start bg-light px-3 w-100 border-1 border-bottom border-main-green card-header-row"
            no-gutters
          >
            <h6 class="py-3">
              Business address and contact details, title, summary and logo
            </h6>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-3 px-3 w-100"
            no-gutters
          >
            <b-overlay :show="isImageLoading" z-index="4" no-wrap></b-overlay>
            <!-- image upload column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-2 py-2 py-lg-0"
              cols="12"
              md="3"
            >
              <template v-if="imageData">
                <!-- <b-overlay :show="isImageLoading" class="d-inline-block" z-index="4" no-wrap> -->
                <b-overlay
                  :show="isImageUpload"
                  spinner-variant="primary"
                  spinner-type="grow"
                  rounded="sm"
                >
                  <div class="backdrop">
                    <img
                      class="d-block mx-auto fit-img"
                      :src="imageData"
                      :width="240"
                      :height="175"
                      :class="isImageValid ? '' : 'invalid-logo'"
                    />
                  </div>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-center"
                    no-gutters
                  >
                    <!-- remove button -->
                    <div @click="chooseImage">
                      <span
                        class="font-color font-weight-bold"
                        style="cursor: pointer"
                      >
                        Change
                      </span>

                      <input
                        class="file-input"
                        ref="fileInput"
                        type="file"
                        @input="onSelectFile"
                      />
                    </div>
                    <button
                      v-if="!imageUrl"
                      class="upload-btn"
                      @click="uploadImage"
                    >
                      Upload Image
                    </button>
                  </b-row>
                </b-overlay>
                <!-- </b-overlay> -->
              </template>
              <template v-else>
                <div @click="chooseImage">
                  <span v-if="!imageData" class="placeholder">
                    Upload your logo
                  </span>
                  <input
                    class="file-input"
                    ref="fileInput"
                    type="file"
                    @input="onSelectFile"
                  />
                </div>
                <!-- <b-button
                  variant="main-green"
                  class="primary upload-btn d-flex flex-row align-items-center justify-content-center border-0 rounded-lg"
                  pill
                >
                  <span class="d-inline">Upload your logo</span>
                </b-button> -->
                <!-- <InvoiceImageUpload
                  @getImageUrl="(e) => (imageUrl = e)"
                  @getImage="(e) => (imageFile = e)"
                  placeholder="Upload your associated media files here"
                /> -->
              </template>
            </b-col>
            <!-- Invoice details column -->
            <b-col md="3">
              <b-icon
                icon="info-circle-fill"
                scale="1.5"
                class="info-circle"
                v-b-tooltip.hover
                id="button-2"
              ></b-icon>
              <b-tooltip target="button-2" placement="right" variant="light">
                <p class="m-0">Logo guidelines</p>
                <ul>
                  <li style="font-size: 12px">
                    <p class="p-0 m-0" style="text-align: left">
                      Images must be less than 5MB in size.
                    </p>
                  </li>
                  <li style="font-size: 12px">
                    <p class="p-0 m-0" style="text-align: left">
                      Images must be at least 500x500px in size..
                    </p>
                  </li>
                  <li style="font-size: 12px">
                    <p class="p-0 m-0" style="text-align: left">
                      PNG and JPG is the preferred format..
                    </p>
                  </li>
                </ul>
              </b-tooltip>
              <!-- <p class="m-0 text-muted">Logo guidelines</p>
              <div class="mx-2">
                <ul style="padding: 0; margin-left: 10px;">
                  <li style="font-size: 12px;" class="text-muted">
                    Images must be less than 5MB in size.
                  </li>
                  <li style="font-size: 12px;" class="text-muted">
                    Images must be at least 500x500px in size.
                  </li>
                  <li style="font-size: 12px;" class="text-muted">
                    PNG and JPG is the preferred format.
                  </li>
                </ul>
              </div> -->
            </b-col>
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="12"
              md="6"
            >
              <!-- full row input (Invoice) -->
              <b-row
                class="d-flex flex-row align-items-end justify-content-end w-50 mb-3"
                no-gutters
              >
                <!-- <FormInput
                      groupId="invoice-title-group"
                      id="invoice-title-group-input"
                      class="text-prime-gray rounded-2"
                      :label="!isQuote ? 'Invoice Title' : 'Quote'"
                      v-model="invoiceTitle"
                      :state="invoiceTitleError.status"
                      :invalidFeedback="`${invoiceTitleError.message}`"
                      isRequired
                      :whiteBG="false"
                      :counter="80"
                      trim
                      form="login-form"
                      type="text"
                    ></FormInput> -->
                <b-form-input
                  list="my-list-id"
                  groupId="user-name-group"
                  id="user-name-group-input"
                  name="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  :placeholder="!isQuote ? 'Invoice Title' : 'Quote Title'"
                  v-model="invoiceTitle"
                  isRequired
                  :whiteBG="false"
                  :counter="80"
                  trim
                  type="text"
                  style="margin-bottom: 25px"
                  autocomplete="off"
                >
                </b-form-input>
                <datalist id="my-list-id" v-if="!isQuote">
                  <option v-for="size in titleList" :key="size">
                    {{ size }}
                  </option>
                </datalist>
                <datalist id="my-list-id" v-else>
                  <option v-for="size in titleListQuotes" :key="size">
                    {{ size }}
                  </option>
                </datalist>
                <!-- <b-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    variant="light"
                    class="w-100 py-0"
                    toggle-class="border border-dark-subtle bg-white rounded-lg border"
                    menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
                    left
                    no-caret
                    no-flip
                    text="Button text via Prop"
                >
                  <template v-slot:button-content>
                    <b-button
                        block
                        id="customer-modal-btn"
                        variant="transparent"
                        class="w-100 d-flex  align-items-center justify-content-between
                 rounded-lg p-0 m-0"
                    >
                      <span>{{
                          !isQuote ? 'Invoice Title' : 'Quote Title'
                        }}</span>
                      <b-icon
                          icon="chevron-down"
                          font-scale="0.9"
                      ></b-icon>
                    </b-button>
                  </template>
                  <template v-if="!isQuote">
                    <b-dropdown-item
                        @click="() => selectTitle(title)"
                        href="#"
                        v-for="(title, index) in titleList"
                        :key="index"
                    >
                      {{ title }}
                    </b-dropdown-item>
                  </template>
                  <template v-else>
                    <b-dropdown-item
                      @click="() => selectTitle(title)"
                      href="#"
                      v-for="(title, index) in titleListQuotes"
                      :key="index"
                  >
                    {{ title }}
                  </b-dropdown-item>
                  </template>

                </b-dropdown> -->
              </b-row>
              <!-- full row input (Summary) -->
              <b-row
                class="d-flex flex-row align-items-end justify-content-end w-50"
                no-gutters
              >
                <b-col cols="12" lg="12">
                  <FormInput
                    groupId="invoice-summary-group"
                    id="invoice-summary-group-input"
                    class="text-prime-gray rounded-2"
                    label="Summary"
                    v-model="invoiceSummary"
                    :state="invoiceSummaryError.status"
                    :invalidFeedback="`${invoiceSummaryError.message}`"
                    isRequired
                    :whiteBG="false"
                    :counter="80"
                    trim
                    form="login-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row
                class="d-flex flex-row align-items-end justify-content-end w-100"
                no-gutters
              >
                <span
                  class="text-left text-dark font-primary font-weight-bold"
                  >{{ currentBusiness.name ? currentBusiness.name : "" }}</span
                >
              </b-row>
              <b-row
                class="d-flex flex-row align-items-end justify-content-end w-100"
                no-gutters
              >
                <span
                  class="text-left text-gray-750 font-primary font-weight-normal"
                  >{{
                    currentBusiness.address ? currentBusiness.address.city : ""
                  }}</span
                >
              </b-row>
              <b-row
                class="d-flex flex-row align-items-end justify-content-end w-100"
                no-gutters
              >
                <span
                  class="text-left text-gray-750 font-primary font-weight-normal"
                  >{{
                    currentBusiness.country ? currentBusiness.country : ""
                  }}</span
                >
              </b-row>
              <b-row
                class="d-flex flex-row align-items-end justify-content-end w-100 pt-3 pt-lg-0"
                no-gutters
              >
                <b-link
                  href="#"
                  class="text-left text-main-green font-primary font-weight-normal"
                  @click="openUpdateCurrentWorkspaceModal($event.target)"
                  >Edit your business address and contact details
                </b-link>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <!-- add customer row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3 main-card-row"
        no-gutters
      >
        <b-card
          no-body
          class="position-relative shadow-sm border-1 border-main-green insight-cards-row w-100"
        >
          <!-- <b-row
            class="d-flex flex-row align-items-center justify-content-start bg-transparent px-3 w-100 card-header-row"
            no-gutters
          >
            <h6 class="py-3">
              Bill to
            </h6>
          </b-row> -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-start bg-transparent px-3 w-100 card-header-row"
            no-gutters
          >
            <h6 class="py-3">Add a customer / supplier</h6>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start my-3 my-md-2 my-lg-3 px-3 w-100"
            no-gutters
          >
            <template v-if="!isCustomerDetails">
              <b-overlay
                :show="isCustomerLoading"
                z-index="4"
                no-wrap
              ></b-overlay>
              <!-- customer billing details column -->
              <b-col
                class="d-flex flex-column align-items-start justify-content-start py-2 py-lg-0 customer-details-col"
                cols="12"
                md="4"
                lg="6"
              >
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100"
                  no-gutters
                >
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-start py-2 py-lg-0"
                    cols="12"
                    md="6"
                  >
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <h6
                        class="text-left text-dark font-primary font-weight-bold mb-2"
                      >
                        Bill to:
                      </h6>
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-bold"
                        >{{ this.billingDetails.customerName }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.billingDetails.fullName }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.billingDetails.cityName }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 mb-2"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.billingDetails.countryName }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.billingDetails.customerPhone }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.billingDetails.customerEmail }}</span
                      >
                    </b-row>
                  </b-col>
                  <!-- customer shipping details  -->
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-start py-2 py-lg-0"
                    cols="12"
                    md="6"
                  >
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <h6
                        class="text-left text-dark font-primary font-weight-bold mb-2"
                      >
                        Ship to:
                      </h6>
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-bold"
                        >{{ this.shippingDetails.customerName }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.shippingDetails.fullName }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.shippingDetails.cityName }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 mb-2"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.shippingDetails.countryName }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.shippingDetails.customerPhone }}</span
                      >
                    </b-row>
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100"
                      no-gutters
                    >
                      <span
                        class="text-left text-dark font-primary font-weight-normal"
                        >{{ this.shippingDetails.customerEmail }}</span
                      >
                    </b-row>
                  </b-col>
                </b-row>
                <!-- action buttons row  -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 my-4"
                  no-gutters
                >
                  <b-col>
                    <b-link
                      href="#"
                      class="text-left text-main-green font-primary font-weight-normal mr-4"
                      @click="
                        openAddCustomerModal($event.target, `${modelType}`)
                      "
                      >Edit
                    </b-link>

                    <!-- change customer not ready from backend -->
                    <b-link
                      href="#"
                      class="text-left text-gray-750 font-primary font-weight-normal ml-3"
                      @click="changeCustomer"
                      >Change
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>
            </template>
            <template v-else>
              <!-- add customer column -->
              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="12"
                md="4"
                lg="6"
              >
                <!-- add customer dropdown -->
                <b-dropdown
                  id="dropdown-form"
                  ref="dropdown"
                  variant="light"
                  class="mx-0 mx-md-2 my-2 my-md-0 filter-dropdown"
                  toggle-class="px-2 px-sm-3 border border-gray-550 bg-white rounded-lg"
                  menu-class="w-100 bg-white border border-main-green py-0 filter-dropdown-menu"
                  left
                  no-caret
                  no-flip
                  @show="filterDropDownShow = true"
                  @hide="filterDropDownShow = false"
                >
                  <template v-slot:button-content>
                    <b-button
                      id="customer-modal-btn"
                      variant="transparent"
                      class="d-flex flex-row align-items-center justify-content-center mr-auto mx-2 my-4 rounded-lg border-0 add-customer-btn"
                    >
                      <b-icon
                        class="text-main-green"
                        width="60"
                        height="60"
                        icon="person-plus"
                        aria-label="Add Customer"
                      ></b-icon>
                    </b-button>
                  </template>
                  <b-dropdown-group flush class="rounded-lg">
                    <template v-for="(customer, index) in customersList">
                      <b-dropdown-item-button
                        button-class="px-2 py-2"
                        :key="index"
                        @click="AddCustomerToInvoice(index)"
                      >
                        <b-row
                          class="d-flex flex-row align-items-center justify-content-start"
                          no-gutters
                        >
                          <b-col
                            class="d-flex flex-column align-items-start justify-content-center px-0"
                          >
                            <span class="main-text">{{
                              customer.customerName
                            }}</span>
                            <span class="text-muted sub-text">{{
                              customer.fullName
                            }}</span>
                          </b-col>
                        </b-row>
                      </b-dropdown-item-button>
                    </template>
                    <b-dropdown-item-button
                      button-class="px-2 py-2 text-main-green"
                      @click="openAddCustomerModal($event.target, 'customer')"
                    >
                      <b-icon
                        class="text-main-green mr-1"
                        icon="plus-circle-fill"
                        size="sm"
                        aria-label="Plus"
                        font-scale="1.1"
                      ></b-icon>
                      Add a new customer
                    </b-dropdown-item-button>
                  </b-dropdown-group>

                  <hr />
                  <!-- suppliers -->

                  <b-dropdown-group flush class="rounded-lg">
                    <template v-for="(supplier, index) in supplierList">
                      <b-dropdown-item-button
                        button-class="px-2 py-2"
                        :key="index"
                        @click="AddSupplierToInvoice(index)"
                      >
                        <b-row
                          class="d-flex flex-row align-items-center justify-content-start"
                          no-gutters
                        >
                          <b-col
                            class="d-flex flex-column align-items-start justify-content-center px-0"
                          >
                            <span class="main-text">{{
                              supplier.customerName
                            }}</span>
                            <span class="text-muted sub-text">{{
                              supplier.fullName
                            }}</span>
                          </b-col>
                        </b-row>
                      </b-dropdown-item-button>
                    </template>
                    <b-dropdown-item-button
                      button-class="px-2 py-2 text-main-green"
                      @click="openAddCustomerModal($event.target, 'Supplier')"
                    >
                      <b-icon
                        class="text-main-green mr-1"
                        icon="plus-circle-fill"
                        size="sm"
                        aria-label="Plus"
                        font-scale="1.1"
                      ></b-icon>
                      Add a new supplier
                    </b-dropdown-item-button>
                  </b-dropdown-group>
                </b-dropdown>
              </b-col>
            </template>
            <!-- invoice details column -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="12"
              md="8"
              lg="6"
            >
              <!-- full row input (Invoice number) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100"
                no-gutters
              >
                <b-col cols="12" md="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >{{ !isQuote ? "Invoice number" : "Quote Number" }}</span
                  >
                </b-col>
                <b-col cols="12" md="6">
                  <FormInput
                    groupId="invoice-number-group"
                    id="invoice-number-group-input"
                    class="text-prime-gray rounded-2"
                    :label="!isQuote ? 'Invoice number' : 'Quote Number'"
                    v-model="invoiceNumber"
                    :state="invoiceNumberError.status"
                    :invalidFeedback="`${invoiceNumberError.message}`"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="text"
                    @input="checkInvoiceNumber"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (P.O/S.O. number) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100"
                no-gutters
              >
                <b-col cols="12" md="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >GST/VAT number</span
                  >
                </b-col>
                <b-col cols="12" md="6">
                  <FormInput
                    groupId="po-so-number-group"
                    id="po-so-number-group-input"
                    class="text-prime-gray rounded-2"
                    label="GST/VAT number"
                    v-model="poNumber"
                    :state="poNumberError.status"
                    :invalidFeedback="`${poNumberError.message}`"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (Invoice date) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 mb-2"
                no-gutters
              >
                <b-col cols="12" md="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >{{ !isQuote ? "Invoice date" : "Quote date" }}</span
                  >
                </b-col>
                <b-col cols="12" md="6">
                  <b-input-group>
                    <b-form-input
                      id="example-input"
                      :value="formatDate(invoiceDate)"
                      type="text"
                      disabled
                      class="date-picker"
                      :placeholder="!isQuote ? 'Invoice date' : 'Quote date'"
                      autocomplete="off"
                      :state="invoiceDateError.status"
                      :invalidFeedback="`${invoiceDateError.message}`"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        class="date-picker-btn font-primary datepicker align-items-center"
                        v-model="invoiceDate"
                        no-flip
                        button-only
                        button-variant="white"
                        locale="en-US"
                        right
                        nav-button-variant="main-green"
                        today-variant="main-green"
                        menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                        aria-controls="start-date-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <!-- full row input (Payment due) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 mt-2"
                no-gutters
              >
                <b-col cols="12" md="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >{{!isQuote ? 'Payment due' :'Quote valid date'}}</span
                  >
                </b-col>
                <b-col cols="12" md="6">
                  <b-input-group>
                    <b-form-input
                      id="example-input"
                      :value="formatDate(paymentDue)"
                      type="text"
                      disabled
                      :placeholder="!isQuote ? 'Payment due' :'Quote valid date'"
                      autocomplete="off"
                      :state="paymentDueError.status"
                      :invalidFeedback="`${paymentDueError.message}`"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        class="date-picker-btn font-primary datepicker align-items-center"
                        v-model="paymentDue"
                        :min="minEndDate"
                        no-flip
                        button-only
                        button-variant="white"
                        locale="en-US"
                        right
                        nav-button-variant="main-green"
                        today-variant="main-green"
                        menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                        aria-controls="start-date-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <!-- Items row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 pt-1 px-3 main-card-row"
        no-gutters
      >
        <b-card
          no-body
          class="shadow-sm border-1 border-main-green items-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start bg-light border-bottom border-main-green px-3 w-100 card-header-row"
            no-gutters
          >
            <h6 class="py-3">Items</h6>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-3 px-3 w-100"
            no-gutters
          >
            <!-- Add Items Table  -->
            <AddItemsInvoiceCopy
              v-if="!isQuote"
              :itemContent="productItems"
              :tax="tax"
              :conversions="{ conversion_rate, anchor_currency, currency }"
              @getProductItems="getProductItemsFn"
              @updateTax="updateTaxValue"
              @total_amount="updateTotalAmount"
              @conversion_data="updateConversions"
            />
            <AddItemsQuoteCopy
              v-else
              :conversions="{ conversion_rate, anchor_currency, currency }"
              :item-content="productItems"
              :tax="tax"
              @getProductItems="getProductItemsFn"
              @updateTax="updateTaxValue"
              @total_amount="updateTotalAmount"
              @conversion_data="updateConversions"
            />
          </b-row>
        </b-card>
      </b-row>
      <!-- Notes / Terms row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3 main-card-row"
        no-gutters
      >
        <b-card
          no-body
          class="position-relative overflow-hidden notes-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start w-100 card-header-row"
            no-gutters
          >
            <div class="d-flex align-items-center justify-content-center">
              <h6 class="p-0">Notes / Terms</h6>
              <b-icon
                icon="plus-circle"
                aria-label="Remove Item"
                class="plus-icon"
                @click="displayNotes"
                v-if="!notes"
              ></b-icon>
            </div>
            <!-- plus-circle -->
          </b-row>
          <Transition>
            <b-row
              class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-3 w-100"
              no-gutters
              v-if="notes"
            >
              <!-- full row input (invoice Notes) -->
              <b-row
                class="d-flex flex-row align-items-end justify-content-start w-100"
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="user-name-group"
                    id="user-name-group-input"
                    class="text-prime-gray border border-main-green note-textArea"
                    v-model="invoiceNotes"
                    :state="invoiceNotesError.status"
                    :invalidFeedback="`${invoiceNotesError.message}`"
                    isRequired
                    :whiteBG="false"
                    isTextArea
                    trim
                    form="login-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
            </b-row>
          </Transition>
        </b-card>
      </b-row>
      <!-- Footer row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3 main-card-row"
        no-gutters
      >
        <b-row
          class="d-flex flex-row align-items-center justify-content-start w-100 card-header-row mb-3"
          no-gutters
        >
          <div class="d-flex align-items-center justify-content-center">
            <h6 class="p-0">Footer</h6>

            <b-icon
              v-if="!footer"
              icon="plus-circle"
              aria-label="Remove Item"
              class="plus-icon"
              @click="displayFooter"
            ></b-icon>
          </div>
        </b-row>
        <b-card
          no-body
          class="position-relative overflow-hidden footer-cards-row w-100"
          v-if="footer"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start bg-light border border-bottom-0 border-main-green px-3 w-100 card-header-row"
            no-gutters
          >
            <h6 class="py-3">Footer</h6>
          </b-row>
          <b-row class="border border-0" no-gutters>
            <!-- full row input (invoice Notes) -->
            <b-row
              class="flex-row align-items-end justify-content-start w-100"
              no-gutters
            >
              <b-col cols="12">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray border border-main-green"
                  v-model="invoiceFooter"
                  :state="invoiceFooterError.status"
                  :invalidFeedback="`${invoiceFooterError.message}`"
                  isRequired
                  isTextArea
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
          </b-row>
        </b-card>
      </b-row>
      <!-- form action buttons  -->
      <b-row
        class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end px-4 mb-4 pb-4 w-100"
      >
        <b-button
          variant="gray-550"
          pill
          class="my-2 my-sm-0 mr-0 mr-sm-2 py-2 px-4 border-2 border-main-green bg-white text-main-green action-btn"
          v-if="isEditInvoice"
          @click="previewInvoice()"
        >
          Preview
        </b-button>
        <b-button
          :isLoading="isLoading"
          :disabled="isLoading"
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 ml-sm-2 py-2 px-4 border-0 action-btn-save"
          @click="createInvoice()"
        >
          Save and continue
          <b-icon
            v-if="limits.remaining_invoice === 0"
            icon="lock-fill"
            class=""
            scale="1.1"
            aria-hidden="true"
            variant="warning"
            title="You've reached to the maximum invoice limit on your package"
          ></b-icon>
        </b-button>
      </b-row>
      <UpdateCurrentWorkspaceModal
        :itemContent="rowItem"
        @reloadFn="callInitFn2"
        :key="showUpdateWorkspaceModalKey"
      />
      <AddCustomerModal
        :itemContent="rowItem"
        @reloadFn="callInitFn"
        :key="showAddCustomerModalKey"
      />
      <AddSupplierModal
        :itemContent="rowItem"
        @reloadFn="callInitFn"
        :key="showAddSupplierModalKey"
      />
    </b-container>
  </HomeLayout>
</template>

<script>
import { mapGetters } from "vuex";
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormInput from "@/components/Form/FormInput";
import AddCustomerModal from "@/components/Invoices/modals/AddCustomerModal";
import AddSupplierModal from "@/components/Invoices/modals/AddSupplierModal";
import UpdateCurrentWorkspaceModal from "@/components/Invoices/modals/UpdateCurrentWorkspaceModal";
import AddItemsInvoiceCopy from "@/components/Invoices/InvoicesCreate/AddItemsInvoiceCopy";
import { UploadLogo } from "@/services/settingsbusiness.service";
// import FormFile from "@/components/Form/FormFile";
// import InvoiceImageUpload from "@/components/ImageUpload/InvoiceImageUpload";

// services
import {
  GetAllCustomers,
  GetSingleCustomer,
  GetSingleSupplier,
  GetAllSuppliers,
} from "@/services/customers.service";
import {
  CreateInvoice,
  GetSingleInvoice,
  UpdateInvoice,
  // InvoiceShareLink,
  GetPackageLimits,
  GetInvoicePreview,
  GetAllInvoices,
} from "@/services/invoice.service";
import {
  GetInvoiceDefaults,
  GetCurrentWorkspace,
} from "@/services/settingsbusiness.service";
import { pairConversion } from "@/services/currency.service";
import AddItemsQuoteCopy from "@/components/Quotes/AddItemsQuoteCopy";


export default {
  name: "InvoicesCreate",
  components: {
    AddItemsQuoteCopy,
    HomeLayout,
    FormInput,
    AddCustomerModal,
    AddSupplierModal,
    UpdateCurrentWorkspaceModal,
    AddItemsInvoiceCopy,
    // FormFile,
    // InvoiceImageUpload,
  },
  data() {
    return {
      isLoading: false,
      isImageUpload: false,
      currency: localStorage.getItem("CURRENCY"),
      isImageValid: true,
      selectedImage: null,
      imageData: null,
      footer: false,
      notes: false,
      totalAmount: 0.0,
      vatLabel: null,
      tax: 0.0,
      rowItem: null,
      showAddCustomerModalKey: Number(new Date()) + 100,
      showAddSupplierModalKey: Number(new Date()) + 1000,
      showUpdateWorkspaceModalKey: Number(new Date()) + 50,
      currentBusiness: {},
      isEditInvoice: false,
      invoiceShareUrl: null,
      invoiceTitle: null,
      invoiceSummary: null,
      imageFile: null,
      imageUrl: null,
      isImageLoading: false,
      customerId: null,
      customerName: null,
      invoiceNumber: null,
      poNumber: null,
      invoiceDate: null,
      paymentDue: null,
      invoiceFooter: null,
      invoiceNotes: null,
      formatted: "",
      selected: "",
      startDate: null,
      endDate: null,
      modelType: null,
      billingDetails: {
        customerName: null,
        fullName: null,
        cityName: null,
        countryName: null,
        customerPhone: null,
        customerEmail: null,
      },
      shippingDetails: {
        customerName: null,
        fullName: null,
        cityName: null,
        countryName: null,
        customerPhone: null,
        customerEmail: null,
      },
      customersList: [],
      supplierList: [],
      itemsList: [
        // {
        //   itemDetails: {
        //     itemName: null,
        //     itemDescription: null,
        //   },
        //   itemQuantity: null,
        //   itemPrice: null,
        //   totalAmount: null,
        // },
      ],
      productItems: [],
      isCustomerDetails: true,
      isCustomerLoading: false,
      invoiceTitleError: {
        status: null,
        message: "",
      },
      invoiceSummaryError: {
        status: null,
        message: "",
      },
      invoiceNumberError: {
        status: null,
        message: "",
      },
      invoiceDateError: {
        status: null,
        message: "",
      },
      paymentDueError: {
        status: null,
        message: "",
      },
      invoiceNotesError: {
        status: null,
        message: "",
      },
      invoiceFooterError: {
        status: null,
        message: "",
      },
      poNumberError: {
        status: null,
        message: "",
      },
      titleList: [
        "Pro Forma Invoice",
        "Credit Note",
        "Tax Invoice",
        "Void Invoice",
      ],
      titleListQuotes: [" Sales Quotes", "Purchase Orders"],
      limits: "",
      conversion_rate: null,
      anchor_currency: null,

      invoiceNumbers: [],
  
      selectedCustomer: undefined,
      selectedCustomerName: undefined,
      searchTable: null,
      selectedStatus: undefined,
      isFiltersApplied: false,
      selectedAgeRange: undefined,
      customer :true,
      isInvoice : true
      
      
    };
  },
  watch: {
    invoiceTitle(val) {
      // if (val) {
      //   this.questionTitleError.status = true;
      // } else {
      //   this.questionTitleError.status = false;
      //   this.questionTitleError.message = "Please enter the question title";
      // }
      if (val != null) {
        if (val.length > 0 && val.length <= 80) {
          this.invoiceTitleError.status = true;
        } else {
          this.invoiceTitleError.status = false;
          this.invoiceTitleError.message =
            "Title should have less than 80 characters";
        }
      } else this.invoiceTitleError.status = null;
    },
    invoiceSummary(val) {
      // if (val) {
      //   this.questionTitleError.status = true;
      // } else {
      //   this.questionTitleError.status = false;
      //   this.questionTitleError.message = "Please enter the question title";
      // }
      if (val != null) {
        if (val.length > 0 && val.length <= 80) {
          this.invoiceSummaryError.status = true;
        } else {
          this.invoiceSummaryError.status = false;
          this.invoiceSummaryError.message =
            "Summary should have less than 80 characters";
        }
      } else {
        this.invoiceSummaryError.status = null;
      }
    },
    invoiceNumber(val) {
      if (val) {
        this.checkInvoiceNumber();
      } else {
        this.invoiceNumberError.status = false;
        this.invoiceNumberError.message = "Please enter the invoice number";
      }
    },
    
    poNumber(val) {
      if (val) {
        this.poNumberError.status = true;
      } else {
        this.poNumberError.status = false;
        this.poNumberError.message = "Please GST/VAT number";
      }
    },
    invoiceDate(val) {
      if (val) {
        this.invoiceDateError.status = true;
      } else {
        // reset the start date when end date changes
        this.paymentDue = "";
        this.invoiceDateError.status = false;
        this.invoiceDateError.message = "Please enter the invoice date";
      }
    },
    paymentDue(val) {
      if (val) {
        this.paymentDueError.status = true;
      } else {
        this.paymentDueError.status = false;
        this.paymentDueError.message = "Please enter the payment due date";
      }
    },
    invoiceNotes(val) {
      // if (val) {
      //   this.questionTitleError.status = true;
      // } else {
      //   this.questionTitleError.status = false;
      //   this.questionTitleError.message = "Please enter the question title";
      // }
      if (val != null) {
        if (val.length == 0) {
          this.invoiceNotesError.status = null;
        } else if (val.length <= 200) {
          this.invoiceNotesError.status = true;
        } else {
          this.invoiceNotesError.status = false;
          this.invoiceNotesError.message =
            "Notes should have less than 200 characters";
        }
      } else this.invoiceNotesError.status = null;
    },
    invoiceFooter(val) {
      // if (val) {
      //   this.questionTitleError.status = true;
      // } else {
      //   this.questionTitleError.status = false;
      //   this.questionTitleError.message = "Please enter the question title";
      // }
      if (val != null) {
        if (val.length == 0) {
          this.invoiceFooterError.status = null;
        } else if (val.length <= 200) {
          this.invoiceFooterError.status = true;
        } else {
          this.invoiceFooterError.status = false;
          this.invoiceFooterError.message =
            "Footer should have less than 200 characters";
        }
      } else this.invoiceFooterError.status = null;
    },
  },
  computed: {
    minEndDate() {
      return this.invoiceDate;
    },
    ...mapGetters({
      getCustomerId: "invoiceCreate/getCustomerId",
      getSupplierId: "invoiceCreate/getSupplierId",
      getInvoiceDefaults: "invoiceCreate/getInvoiceDefaults",
      // getCurrentWorkspace: "auth/getCurrentWorkspace",
    }),
    isQuote() {
      return window.location.href.toString().includes("quotes");
    },
    computedTitleValidation() {
      if (this.invoiceTitle) {
        if (this.invoiceTitle.length <= 80) {
          return true;
        }
        return false;
      } else return false;
    },
    computedSummaryValidation() {
      if (this.invoiceSummary) {
        if (this.invoiceSummary.length <= 80) {
          return true;
        }
        return false;
      } else return true;
    },
    computedNotesValidation() {
      if (this.invoiceNotes) {
        if (this.invoiceNotes.length <= 200) {
          return true;
        }
        return false;
      } else return true;
    },
    computedFooterValidation() {
      if (this.invoiceFooter) {
        if (this.invoiceFooter.length <= 200) {
          return true;
        }
        return false;
      } else return true;
    },
    // invoiceCustomerId: {
    //   get() {
    //     return this.getCustomerId;
    //   },
    // },
    isSupplier() {
      return window.location.href.toString().includes("supplier");
    },
  },
  async mounted() {
    // default invoice
    // console.log("getInvoiceDefaults1", this.getInvoiceDefaults);
    // await this.initDefaultInvoice();
    // if (this.invoiceTitle) {
    //   await this.initDefaultInvoice();
    //   console.log("getInvoiceDefaults2", this.invoiceTitle);
    // } else {
    await this.initGetAllInvoicesFn();
    await this.initFn();
    await this.initCurrentWS();
    // }
    this.isEditInvoice = this.$route.meta.isEdit;
    if (this.isEditInvoice) {
      this.isCustomerDetails = false;
      await this.initEditInvoiceFn(this.$route.params.invoiceId);
      console.log("edit products", this.productItems);
      // console.log(this.$route.params.invoiceId);
    }
    // create invoice from customer table.
    if (this.getCustomerId) {
      this.isCustomerDetails = false;
      // get customer data to the invoice with this function.
      await this.createInvoicefromCustomer(this.getCustomerId);
      this.$store.dispatch("invoiceCreate/setCustomerId", null);
    }
    if (this.getSupplierId) {
      this.isCustomerDetails = false;
      // get customer data to the invoice with this function.
      await this.createInvoicefromSupplier(this.getSupplierId);
      this.$store.dispatch("invoiceCreate/setSupplierId", null);
    }

    this.getPackageLimits();
    await pairConversion({ base: "eur", target: "usd" });
  },
  methods: {
    // selectTitle(title){
    //   this.invoiceTitle = title
    // },
    chooseImage() {
      console.log("called");
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      this.imageUrl = null;
      this.isImageValid = true;
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        this.selectedImage = files[0];
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
        if (files[0].size > 5000000) {
          this.selectedImage = null;
          this.isImageValid = false;
          let payloadNotify = {
            isToast: true,
            title: "Logo sized large",
            content: "Please choose an logo less than 5mb",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      }
    },
    async getPackageLimits() {
      const res = await GetPackageLimits();
      this.limits = res.data;
      console.log(this.limits);
    },
    getVatLabel(country) {
      if (country === "Fiji") {
        return "Vat Rate";
      } else {
        return "GST Rate";
      }
    },
    displayNotes() {
      this.notes = !this.notes;
    },
    displayFooter() {
      this.footer = !this.footer;
    },
    updateTaxValue(value) {
      this.tax = value;
    },
    updateTotalAmount(value) {
      console.log(value);
      this.totalAmount = value;
    },
    async changeCustomer() {
      this.rowItem = false;
      this.isCustomerDetails = true;
      this.customerId = null;
      this.billingDetails.customerName = null;
      this.billingDetails.fullName = null;
      this.billingDetails.cityName = null;
      this.billingDetails.countryName = null;
      this.billingDetails.customerPhone = null;
      this.billingDetails.customerEmail = null;
      this.shippingDetails.customerName = null;
      this.shippingDetails.fullName = null;
      this.shippingDetails.cityName = null;
      this.shippingDetails.countryName = null;
      this.shippingDetails.customerPhone = null;
      this.shippingDetails.customerEmail = null;
      this.rowItem = null;
      try {
        let { data } = await GetAllCustomers();
        this.customersList = data.results.results.map((x) => {
          return {
            customerId: x._id,
            customerName: x.name,
            fullName: x.full_name,
            billingCityName: x.billing_address.city,
            shippingCityName: x.shipping_address.city,
            billingCountryName: x.billing_address.country,
            shippingCountryName: x.shipping_address.country,
            // countryName: "Sri Lanka",
            customerEmail: x.email,
            customerPhone: x.phone,
            shippingName: x.shipping_name,
            shippingPhone: x.shipping_phone,
            shippingEmail: x.shipping_email,
            // customerAmount: {
            //   balance: "Rs. 4,000.00",
            //   overdue: "Rs. 2,000.00 overdue",
            // },
          };
        });
      } catch (e) {
        console.log(e);
      }
      this.$nextTick(() => {
        this.$refs.dropdown.show();
      });
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
    invoiceDatePickerFn() {
      console.log(this.$refs.InvoiceDatePicker);
      this.$refs.InvoiceDatePicker.dp.show();
    },
    removeLinkFn() {
      this.imageUrl = null;
      this.imageFile = null;
    },
    async initCurrentWS() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
      this.tax = data.vat_rate;
      console.log(typeof this.tax);
      this.vatLabel = data?.vat_label || this.getVatLabel(data.country);
      this.currentBusiness = data;
    },
    async initFn() {
      try {
        this.isImageLoading = true;
        let { data } = await GetInvoiceDefaults();
        if (!this.isQuote) {
          this.invoiceTitle = data[0] ? data[0].title : null;
        } else {
          this.invoiceTitle = null;
        }
        this.invoiceSummary = data[0] ? data[0].summary : null;
        this.imageData = data[0] ? data[0].logo : null;
        this.imageUrl = data[0] ? data[0].logo : null;
        this.invoiceNotes = data[0] ? data[0].notes : null;
        this.invoiceFooter = data[0] ? data[0].footer : null;
        this.poNumber = data[0]?data[0].gst:null;
        this.isImageLoading = false;
      } catch (error) {
        this.isImageLoading = false;
        console.log(error);
      }
      console.log("this.isSupplier ", this.isSupplier);
      try {
        // get all customers list from API
        console.log("cus", this.getCustomerId);
        let { data } = await GetAllCustomers();
        console.log("cus data", data);
        this.customersList = data.results.results.map((x) => {
          return {
            customerId: x._id,
            Modeltype: "Customers",
            customerName: x.name,
            fullName: x.full_name,
            billingCityName: x.billing_address.city,
            shippingCityName: x.shipping_address.city,
            billingCountryName: x.billing_address.country,
            shippingCountryName: x.shipping_address.country,
            // countryName: "Sri Lanka",
            customerEmail: x.email,
            customerPhone: x.phone,
            shippingName: x.shipping_name,
            shippingPhone: x.shipping_phone,
            shippingEmail: x.shipping_email,
            // customerAmount: {
            //   balance: "Rs. 4,000.00",
            //   overdue: "Rs. 2,000.00 overdue",
            // },
          };
        });
      } catch (error) {
        console.log(error);
      }

      //get all suppliers
      try {
        // get all customers list from API
        console.log("cus", this.getCustomerId);
        let { data } = await GetAllSuppliers();
        console.log("cus data", data);
        this.supplierList = data.results.results.map((x) => {
          return {
            customerId: x._id,
            Modeltype: "Suppliers",
            customerName: x.name,
            fullName: x.full_name,
            billingCityName: x.billing_address.city,
            shippingCityName: x.shipping_address.city,
            billingCountryName: x.billing_address.country,
            shippingCountryName: x.shipping_address.country,
            // countryName: "Sri Lanka",
            customerEmail: x.email,
            customerPhone: x.phone,
            shippingName: x.shipping_name,
            shippingPhone: x.shipping_phone,
            shippingEmail: x.shipping_email,
            // customerAmount: {
            //   balance: "Rs. 4,000.00",
            //   overdue: "Rs. 2,000.00 overdue",
            // },
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async initEditInvoiceFn(val) {
      // get invoice details to the page
      try {
        this.isCustomerLoading = true;
        console.log("isCustomerLoading", this.isCustomerLoading);
        let { data } = await GetSingleInvoice(val);
        console.log(data);
        this.conversion_rate = data.conversion_rate;
        this.anchor_currency = data.anchor_currency;
        this.currency = data.currency;
        // console.log("get single cus", data.items[0].price);
        this.invoiceTitle = data.title;
        this.imageData = data.logo;
        this.invoiceSummary = data.summary;
        this.invoiceNumber = data.invoice_number;
        this.poNumber = data.po_number;
        this.invoiceDate = data.issued_date.split("T")[0];
        this.paymentDue = data.due_date.split("T")[0];
        this.tax = data.tax;
        // customer details
        this.customerId = data.customer_id._id;
        if (data.customer_id.Supplier_type) {
          this.modelType = "Supplier";
        } else {
          this.modelType = "customer";
        }
        this.billingDetails.customerName = data.customer_id.name;
        this.billingDetails.fullName = data.customer_id.full_name;
        this.billingDetails.cityName = data.customer_id.billing_address.city;
        this.billingDetails.countryName =
          data.customer_id.billing_address.country;
        this.billingDetails.customerPhone = data.customer_id.phone;
        this.billingDetails.customerEmail = data.customer_id.email;
        this.shippingDetails.customerName = data.customer_id.name;
        this.shippingDetails.fullName = data.customer_id.shipping_name;
        this.shippingDetails.cityName = data.customer_id.shipping_address.city;
        this.shippingDetails.countryName =
          data.customer_id.shipping_address.country;
        this.shippingDetails.customerPhone = data.customer_id.shipping_phone;
        this.shippingDetails.customerEmail = data.customer_id.shipping_email;
        // customer details end
        // product items passing as item content to the child component
        this.productItems = data.items.map((x) => {
          return {
            _id: x._id,
            name: x.name,
            description: x.description,
            price: x.price,
            quantity: x.quantity,
            glcode: x.code,
            tax: x.tax_per_item,
          };
        });
        // this.totalAmount = data.customer_id,
        this.invoiceFooter = data.footer;
        this.invoiceNotes = data.notes;
        this.isCustomerLoading = false;
        console.log("isCustomerLoading", this.isCustomerLoading);
      } catch (error) {
        this.isCustomerLoading = false;
        console.log(error);
      }
    },
    AddCustomerToInvoice(index) {
      this.isCustomerDetails = false;
      this.customerId = this.customersList[index].customerId;
      this.modelType = "customer";
      this.billingDetails.customerName = this.customersList[index].customerName;
      this.billingDetails.fullName = this.customersList[index].fullName;
      this.billingDetails.cityName = this.customersList[index].billingCityName;
      this.billingDetails.countryName =
        this.customersList[index].billingCountryName;
      this.billingDetails.customerPhone =
        this.customersList[index].customerPhone;
      this.billingDetails.customerEmail =
        this.customersList[index].customerEmail;
      // shipping details
      this.shippingDetails.customerName =
        this.customersList[index].customerName;
      this.shippingDetails.fullName = this.customersList[index].shippingName;
      this.shippingDetails.cityName =
        this.customersList[index].shippingCityName;
      this.shippingDetails.countryName =
        this.customersList[index].shippingCountryName;
      this.shippingDetails.customerPhone =
        this.customersList[index].shippingPhone;
      this.shippingDetails.customerEmail =
        this.customersList[index].shippingEmail;
    },
    AddSupplierToInvoice(index) {
      this.isCustomerDetails = false;
      this.customerId = this.supplierList[index].customerId;
      this.modelType = "Supplier";
      this.billingDetails.customerName = this.supplierList[index].customerName;
      this.billingDetails.fullName = this.supplierList[index].fullName;
      this.billingDetails.cityName = this.supplierList[index].billingCityName;
      this.billingDetails.countryName =
        this.supplierList[index].billingCountryName;
      this.billingDetails.customerPhone =
        this.supplierList[index].customerPhone;
      this.billingDetails.customerEmail =
        this.supplierList[index].customerEmail;
      // shipping details
      this.shippingDetails.customerName = this.supplierList[index].customerName;
      this.shippingDetails.fullName = this.supplierList[index].shippingName;
      this.shippingDetails.cityName = this.supplierList[index].shippingCityName;
      this.shippingDetails.countryName =
        this.supplierList[index].shippingCountryName;
      this.shippingDetails.customerPhone =
        this.supplierList[index].shippingPhone;
      this.shippingDetails.customerEmail =
        this.supplierList[index].shippingEmail;
    },
    getProductItemsFn(items) {
      console.log(
        "🚀 ~ file: InvoicesCreate.vue:1693 ~ getProductItemsFn ~ items:",
        items
      );
      // let productItems = items.productItems.map((x) => {
      //   return {
      //     itemName: x.itemName,
      //     itemDescription: x.itemDescription,
      //     itemQuantity: x.itemQuantity,
      //     itemPrice: x.itemPrice,
      //     totalAmount: x.totalAmount,
      //   }
      // });
      this.itemsList = items;
      console.log("item list parent", this.itemsList);
    },

    async uploadImage() {
      if (this.selectedImage) {
        this.isImageUpload = true;
        let formData = new FormData();
        formData.append("file", this.selectedImage);
        const response = await UploadLogo(formData);
        this.selectedImage = null;
        this.isImageValid = true;
        if (response.status == 200) {
          let payloadNotify = {
            isToast: true,
            title: "Logo Upload",
            content: "Logo upload successfully",
            variant: "success",
          };
          this.isImageUpload = false;
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.imageUrl = response.data.logo;
        } else {
          let payloadNotify = {
            isToast: true,
            title: "Something Went Wrong",
            content: "Error: Unable to upload the Image",
            variant: "danger",
          };
          this.isImageUpload = false;
          this.$store.dispatch("notification/setNotify", payloadNotify);
          return;
        }
      }
      return;
    },
    async createInvoice() {
      if (this.limits.remaining_invoice === 0) {
        return;
      }
      console.log("item List update/create", this.itemsList);
      console.log("invoice create image", this.imageFile);
      // newItems = this.itemsList.map((x) => {
      //   return {
      //     _id: x.id
      //   }
      // })
      if (
        // this.computedTitleValidation &&
        this.computedSummaryValidation &&
        // this.computedNotesValidation &&
        // this.computedFooterValidation &&
        this.invoiceDate &&
        // this.imageUrl &&
        this.paymentDue &&
        this.itemsList.length > 0 &&
        this.customerId &&
        this.invoiceNumber
      ) {
        if (this.isEditInvoice) {
          let payload = {
            id: this.$route.params.invoiceId,
            title: this.invoiceTitle,
            logo: this.imageUrl,
            summary: this.invoiceSummary,
            invoice_number: this.invoiceNumber,
            po_number: this.poNumber,
            issued_date: this.invoiceDate,
            due_date: this.paymentDue,
            customer_name: this.billingDetails.customerName,
            customer_id: this.customerId,
            total_amount: this.totalAmount,
            footer: this.invoiceFooter,
            notes: this.invoiceNotes,
            items: this.itemsList.map((x) => {
              return {
                _id: x.itemName.itemId,
                name: x.itemName.title,
                description: x.itemDescription,
                price: x.itemUnitPrice ? x.itemUnitPrice : x.itemPrice,
                quantity: x.quantity,
                code: x.glcode,
                tax_per_item: x.tax,
              };
            }),
            is_invoice: !this.isQuote,
            // tax: this.tax,
            tax: 0,
            gst: this.gstAmount,
            vatLabel: this.vatLabel,
            currency: this.currency,
            conversion_rate: this.conversion_rate,
            anchor_currency: this.anchor_currency,
          };
          try {
            this.isLoading = true;
            let { data, status } = await UpdateInvoice(payload);
            if (status == 200) {
              console.log("update invoice", data);
              let payloadNotify = {
                isToast: true,
                title: this.isQuote ? "Quote Updated" : "Invoice Updated",
                content: this.isQuote
                  ? "The Quote has been updated successfully"
                  : "The invoice has been updated successfully",
                variant: "success",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
              this.$router.push(
                this.isQuote
                  ? `/quotes/${this.$route.params.invoiceId}`
                  : `/invoices/${this.$route.params.invoiceId}`
              );
            } else {
              console.log("Server Error");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isLoading = false;
          }
        } else {
          console.log(this.itemsList);
          // const image =
          //   "https://figadev.s3.amazonaws.com/648fcf25180d01ad7ed4475f/648fcf25180d01ad7ed4475f1687146586866.png";
          let payload = {
            title: this.invoiceTitle,
            summary: this.invoiceSummary,
            invoice_number: this.invoiceNumber,
            po_number: this.poNumber,
            issued_date: this.invoiceDate,
            due_date: this.paymentDue,
            customer_name: this.billingDetails.customerName,
            customer_id: this.customerId,
            total_amount: this.totalAmount,
            footer: this.invoiceFooter,
            notes: this.invoiceNotes,
            items: this.itemsList.map((x) => {
              return {
                _id: x.itemName.itemId,
                name: x.itemName.title,
                description: x.itemDescription,
                price: x.itemUnitPrice ? x.itemUnitPrice : x.itemPrice,
                quantity: x.quantity,
                code: x.glcode,
                tax_per_item: x.tax,
              };
            }),

            is_invoice: !this.isQuote,
            // tax: +this.tax,
            tax: 0,
            gst: this.gstAmount,
            vatLabel: this.vatLabel,
            currency: this.currency,
            conversion_rate: this.conversion_rate,
            anchor_currency: this.anchor_currency,
            logo: this.imageUrl,
          };
          try {
            this.isLoading = true;
            let { data, status } = await CreateInvoice(payload);
            if (!status) {
              let payloadNotify = {
                isToast: true,
                title: this.isQuote
                  ? "Invalid Quote Number!"
                  : "Invalid Invoice Number:",
                content: this.isQuote
                  ? "Please use different quote number."
                  : "Please use different invoice number.",
                variant: "danger",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
            }
            if (status == 200) {
            const content = this.isQuote
                  ? "The Quote has been created successfully"
                  : "The invoice has been created successfully"
            this.$root.$bvToast.toast(content, {
              title: this.isQuote ? "Quote Created" : "Invoice Created",
              toaster: 'b-toaster-bottom-right',
              variant: 'success',
              solid: true
            })
            this.$router.push(
                this.isQuote
                  ? `/quotes/${data.invoice._id}`
                  : `/invoices/${data.invoice._id}`
              );
            } else {
              console.log("Server Error");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isLoading = false;
          }
        }
      } else {
        const errorList = this.errorFn();
        const type = this.isQuote;
        const title = type ? "Quote Information" : "Invoice Information";
        let payloadNotify = {
          isToast: true,
          title: title,
          content: "Error: Unable to save the Invoice",
          list: errorList,
          variant: "danger",
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    errorFn() {
      let errorList = [];
      const type = this.isQuote ? "Quote" : "Invoice";
      // if (!this.paymentDue) {
      //   this.invoiceNumberError.status = false;
      //   this.invoiceNumberError.message = "Please fill in the invoice number";
      //   errorList.push(this.invoiceNumberError.message);
      // }
      // invoiceNumberError: {
      //   status: null,
      //   message: "",
      // },
      if (!this.invoiceTitle) {
        this.invoiceTitleError.status = false;
        this.invoiceTitleError.message = "Please fill in the title";
        errorList.push(this.invoiceTitleError.message);
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Please enter the the Invoice title",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      } else if (this.invoiceTitle.length > 80) {
        this.invoiceTitleError.status = false;
        this.invoiceTitleError.message =
          "Title should have less than 80 characters";
        errorList.push(this.invoiceTitleError.message);
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Title should have less than 80 characters",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (this.invoiceSummary && this.invoiceSummary.length > 80) {
        this.invoiceSummaryError.status = false;
        this.invoiceSummaryError.message =
          "Summary should have less than 80 characters";
        errorList.push(this.invoiceSummaryError.message);
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Summary should have less than 80 characters",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (this.invoiceNotes && this.invoiceNotes.length > 200) {
        this.invoiceNotesError.status = false;
        this.invoiceNotesError.message =
          "Notes should have less than 200 characters";
        errorList.push(this.invoiceNotesError.message);
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Notes should have less than 200 characters",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (this.invoiceFooter && this.invoiceFooter.length > 200) {
        this.invoiceFooterError.status = false;
        this.invoiceFooterError.message =
          "Footer should have less than 200 characters";
        errorList.push(this.invoiceFooterError.message);
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Footer should have less than 200 characters",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.invoiceNumber) {
        this.invoiceNumberError.status = false;
        this.invoiceNumberError.message = `Please fill in the ${type} Number`;
        errorList.push(this.invoiceNumberError.message);
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Please enter the the Invoice Number",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.poNumber) {
        this.poNumberError.status = false;
        this.poNumberError.message =
          "Please fill in the Purchase / Sales Number";
        errorList.push(this.poNumberError.message);
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Please enter the the Invoice Number",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.invoiceDate) {
        this.invoiceDateError.status = false;
        this.invoiceDateError.message = "Please fill in the Date";
        errorList.push(this.invoiceDateError.message);
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Please enter the the Invoice Date",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.paymentDue) {
        this.paymentDueError.status = false;
        this.paymentDueError.message = "Please fill in the Due Date";
        errorList.push(this.paymentDueError.message);
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Please enter the the Invoice Due Date",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.customerId) {
        // this.paymentDueError.status = false;
        // this.paymentDueError.message = "Please fill in the Due Date";
        errorList.push("Please add a customer");
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Please add a customer",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (this.itemsList.length <= 0) {
        // this.paymentDueError.status = false;
        // this.paymentDueError.message = "Please fill in the Due Date";
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Invoice must have one or more items",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
        errorList.push(`${type} must have one or more items`);
      }
      if (!this.imageUrl) {
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Error: Unable to save the Invoice",
        //   content: "Please add a logo",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
        // errorList.push("Please add a logo");
      }
      return errorList;
    },
    async createInvoicefromCustomer(val) {
      try {
        this.isCustomerLoading = true;
        let { data } = await GetSingleCustomer(val);
        console.log("cus data", data);
        // customer details
        this.customerId = data._id;
        this.modelType = "customer";
        this.billingDetails.customerName = data.name;
        this.billingDetails.fullName = data.full_name;
        this.billingDetails.cityName = data.billing_address.city;
        this.billingDetails.countryName = data.billing_address.countryName;
        this.billingDetails.customerPhone = data.phone;
        this.billingDetails.customerEmail = data.email;
        this.shippingDetails.customerName = data.name;
        this.shippingDetails.fullName = data.shipping_name;
        this.shippingDetails.cityName = data.shipping_address.city;
        this.shippingDetails.countryName = data.shipping_address.countryName;
        this.shippingDetails.customerPhone = data.shipping_phone;
        this.shippingDetails.customerEmail = data.shipping_email;
        // customer details end
        this.isCustomerLoading = false;
      } catch (error) {
        this.isCustomerLoading = false;
        console.log(error);
      }
    },
    async createInvoicefromSupplier(val) {
      try {
        this.isCustomerLoading = true;
        let { data } = await GetSingleSupplier(val);
        console.log("cus data", data);
        // customer details
        this.customerId = data._id;
        this.modelType = "Supplier";
        this.billingDetails.customerName = data.name;
        this.billingDetails.fullName = data.full_name;
        this.billingDetails.cityName = data.billing_address.city;
        this.billingDetails.countryName = data.billing_address.countryName;
        this.billingDetails.customerPhone = data.phone;
        this.billingDetails.customerEmail = data.email;
        this.shippingDetails.customerName = data.name;
        this.shippingDetails.fullName = data.shipping_name;
        this.shippingDetails.cityName = data.shipping_address.city;
        this.shippingDetails.countryName = data.shipping_address.countryName;
        this.shippingDetails.customerPhone = data.shipping_phone;
        this.shippingDetails.customerEmail = data.shipping_email;
        // customer details end
        this.isCustomerLoading = false;
      } catch (error) {
        this.isCustomerLoading = false;
        console.log(error);
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    openAddCustomerModal(button, x) {
      console.log("clicked", button);
      console.log("x", x);
      if (x === "customer") {
        this.modalThatBtn = button;
        this.rowItem = null;
        (this.rowItem = this.customerId),
          // {
          //   customerId: this.customerId,
          //   customerName: this.billingDetails.customerName,
          //   billingfullName: this.billingDetails.fullName,
          //   billingcityName: this.billingDetails.cityName,
          //   billingcustomerPhone: this.billingDetails.customerPhone,
          //   billingcustomerEmail: this.billingDetails.customerEmail,
          //   billingcustomerName: this.shippingDetails.customerName,
          //   shippingfullName: this.shippingDetails.fullName,
          //   shippingcityName: this.shippingDetails.cityName,
          //   shippingcustomerPhone: this.shippingDetails.customerPhone,
          //   shippingcustomerEmail: this.shippingDetails.customerEmail,
          // };
          console.log("running ", "customer");
        this.$root.$emit("bv::show::modal", `add-customer-modal`, button);
      } else {
        console.log("clicked", button);
        this.modalThatBtn = button;
        this.rowItem = null;
        (this.rowItem = this.customerId),
          console.log(
            "🚀 ~ file: InvoicesCreate.vue:2130 ~ openAddCustomerModal ~ this.rowItem:",
            this.rowItem
          );
        console.log("running ", "Supplier");
        this.$root.$emit("bv::show::modal", `add-supplier-modal`, button);
      }
    },
    // openAddSupplierModal(button) {
    //   console.log("clicked", button);
    //   this.modalThatBtn = button;
    //   this.rowItem = null;
    //   (this.rowItem = this.customerId),
    //       this.$root.$emit("bv::show::modal", `add-supplier-modal`, button);
    // },
    openUpdateCurrentWorkspaceModal(button) {
      console.log("clicked", button);
      this.$root.$emit("bv::show::modal", `update-workspace-modal`, button);
    },
    async callInitFn(data) {
      console.log("from child", data);
      this.isCustomerDetails = false;
      this.customerId = data._id;
      this.billingDetails.customerName = data.name;
      this.billingDetails.fullName = data.full_name;
      this.billingDetails.cityName = data.billing_address.city;
      this.billingDetails.countryName = data.billing_address.country;
      this.billingDetails.customerPhone = data.phone;
      this.billingDetails.customerEmail = data.email;
      this.shippingDetails.customerName = data.name;
      this.shippingDetails.fullName = data.shipping_name;
      this.shippingDetails.cityName = data.shipping_address.city;
      this.shippingDetails.countryName = data.shipping_address.country;
      this.shippingDetails.customerPhone = data.shipping_phone;
      this.shippingDetails.customerEmail = data.shipping_email;
      this.showAddCustomerModalKey = Number(new Date()) + 100;
      this.showAddSupplierModalKey = Number(new Date()) + 1000;
      // await this.initFn();
    },
    async callInitFn2() {
      this.showUpdateWorkspaceModalKey = Number(new Date()) + 50;
      // await this.initFn();
      await this.initCurrentWS();
    },
    async previewInvoice() {
      try {
        console.log("preview invoice fn", this.$route.params.invoiceId);
        // let payload = {
        //   id: this.$route.params.invoiceId,
        // };
        let { data, status } = await GetInvoicePreview(this.$route.params.invoiceId);
        if (status == 200) {
          this.invoiceShareUrl = data;
          // window.open(`${this.invoiceShareUrl}`);
          const newTab =   window.open();
          newTab.document.write(`${this.invoiceShareUrl}`)
        }
      } catch (error) {
        console.log(error);
      }
    },
    updateConversions(data) {
      this.currency = data.currency;
      this.anchor_currency = data.anchor_currency;
      this.conversion_rate = data.rate;
    },
    async initGetAllInvoicesFn() {
      if (this.isQuote){
        this.isInvoice = false
      }else{
        this.isInvoice = true
      }

      try {
       
        let { data: customerData } = await GetAllInvoices(
          this.selectedCustomer,
          this.searchTable,
          this.selectedStatus,
          this.startDate,
          this.endDate,
          undefined,
          0,
          0,
          this.selectedAgeRange,
          this.isInvoice  ,
          this.customer
          
        );
        let { data: supplierData } = await GetAllInvoices(
          this.selectedCustomer,
          this.searchTable,
          this.selectedStatus,
          this.startDate,
          this.endDate,
          undefined,
          0,
          0,
          this.selectedAgeRange,
          this.isInvoice ,
          this.customer = false
          
        );

        // Extract invoice numbers
        let invoicecustomerNumbers = customerData.results.results.map(invoice => invoice.invoice_number);
        let invoicesupplierrNumbers = supplierData.results.results.map(invoice => invoice.invoice_number);
        this.invoiceNumbers = invoicecustomerNumbers.concat(invoicesupplierrNumbers);
      
       
      } catch (error) {
        console.error(error);
       
      } finally {
       console.log("error")
      }
    },
    checkInvoiceNumber() {
      if (!this.invoiceNumber) {
        return;
      }

      if (this.invoiceNumbers.includes(this.invoiceNumber)) {
        if(!this.isQuote){
        this.invoiceNumberError.status = false;
        this.invoiceNumberError.message = 'Invoice number already exists.';
        }else{
        this.invoiceNumberError.status = false;
        this.invoiceNumberError.message = 'Quote number already exists.'; 
        }
        
      } else {
        this.invoiceNumberError.status = true;
        this.invoiceNumberError.message = '';
      }
    },
  },

};
</script>

<style scoped>
.backdrop {
  background: #d1d5db;
  position: relative;
}

.upload-btn {
  color: #0b9b7d;
  background: transparent;
  border: none;
  outline: #0b9b7d;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
  margin-left: 10px;
}

.info-circle {
  /* background: #828282; */
  color: #828282;
}

.font-color {
  color: #0b9b7d;
  margin-top: 5px;
  display: inline-block;
}

.placeholder {
  background: #0b9b7d;
  padding: 10px 30px 10px 30px;
  border-radius: 30px;
  color: #ffffff;
  display: inline-block;
}

.base-image-input {
  display: block;
  width: 100px;
  height: 100px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

/* .upload-btn {
  padding: 10px 30px 10px 30px;
} */

.v-enter-to {
  opacity: 1;
}

.v-enter-active {
  transition: all 0.3s ease;
}

.v-leave-from {
  opacity: 1;
}

.v-leave-active {
  transition: all 0.3s ease-out;
}

.v-leave-to {
  opacity: 0;
}

.plus-icon {
  color: #0b9b7d;
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.fit-img {
  object-fit: cover;
}

.invalid-logo {
  border: 2px solid #df4759;
  border-radius: 10px;
  padding: 5px;
}

.root-container {
  min-height: 600px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
}

.root-container .main-card-row {
}

/* insights card */
.insight-cards-row {
  /* height: 350px; */
  height: auto;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 30px;
}

/* .insight-cards-row .add-customer-btn {
  margin-top: 50px;
} */
.insight-cards-row .customer-details-col {
  font-size: 0.9rem;
}

.insight-cards-row .remove-button {
  width: 280px;
  text-align: left;
  color: #0b9b7d;
}

.insight-cards-row .filter-dropdown {
  height: 200px;
  min-width: 300px;
}

.insight-cards-row .filter-dropdown >>> .add-customer-btn {
  /* height: 50px; */
  padding-left: 100px;
}

.insight-cards-row .filter-dropdown >>> .filter-dropdown-menu {
  height: 200px;
  min-width: 200px;
  /* min-height: 12vh; */
  /* overflow-x: hidden; */
  overflow-y: auto;
  scrollbar-width: thin;
}

.insight-cards-row .date-picker >>> input.form-control:focus {
  height: auto;
  box-shadow: none;
  border-color: #0b9b7d;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.insight-cards-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.insight-cards-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

.items-cards-row {
  height: auto;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 30px;
  overflow: visible;
  position: static;
}

.footer-cards-row {
  height: 260px;
  width: 100%;
  margin-bottom: 30px;
  border: none;
}

.notes-cards-row {
  /* height: 260px; */
  margin-bottom: 15px;
  border: none;
}

.note-textArea {
  border-radius: 5px;
}

.action-btn-save:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}

/* .insight-cards-row .card-header-row {
  border: 5px;
} */
/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

.table-card-row .filter-dropdown,
.table-card-row .export-dropdown {
  height: 40px;
  min-width: 330px;
  max-width: 350px;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}

.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 8px;
  height: 8px;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active,
.table-card-row
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

.table-card-row .export-dropdown >>> .export-dropdown-button {
  padding: 6px 20px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .insight-cards-row {
    height: 350px;
  }

  .insight-cards-row .filter-dropdown {
    min-width: 35px;
  }

  .insight-cards-row .filter-dropdown >>> .add-customer-btn {
    height: 50px;
    padding-right: 100px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: auto;
  }

  .insight-cards-row .filter-dropdown {
    height: 200px;
    min-width: auto;
  }

  .insight-cards-row .filter-dropdown >>> .add-customer-btn {
    /* height: 50px; */
    /* padding-left: 100px */
  }

  .table-card-row .search-input-group {
    max-width: none;
  }
}
</style>
